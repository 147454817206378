(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/section-text-image/assets/javascripts/init-actions.js') >= 0) return;  svs.modules.push('/components/banner_layouts/section-text-image/assets/javascripts/init-actions.js');
"use strict";


const initialize = () => {
  const {
    productIds
  } = svs.components.lbUtils.sportProducts;
  const logger = svs.core.log.getLogger('banner_layouts:section-text-image');
  const elements = document.querySelectorAll('.js-hero-v2[data-type="section-text-image"]');
  const shouldReduceMotion = window.matchMedia("(prefers-reduced-motion: reduce)") === true || window.matchMedia("(prefers-reduced-motion: reduce)").matches === true;
  const cubes = [];
  const offset = {
    x: 0,
    y: 0
  };
  const speed = shouldReduceMotion ? 0 : 30;
  let animationComplete = false;
  let requestAnimationFrameId = null;
  const updateCube = cube => {
    var _cube$dataset;
    const productId = Number(cube === null || cube === void 0 || (_cube$dataset = cube.dataset) === null || _cube$dataset === void 0 ? void 0 : _cube$dataset.id);
    const delta = performance.now() * speed / 1000 % 360;
    offset.x = Math.cos(delta * Math.PI / 180) / 3;
    offset.y = Math.sin(delta * Math.PI / 180) / 3;
    let rx = -offset.x * 10 - 10;
    let ry = offset.y * 50 + 40;
    let rz = 8;
    switch (productId) {
      case productIds.FULLTRAFF:
      case productIds.MALTIPSET:
        rz = -8;
        break;
      case productIds.BINGO:
        rx = 7 - offset.x * 20;
        ry = 43 + offset.y * 50;
        rz = 351;
        break;
      default:
    }
    const sizeMatch = cube.offsetHeight;
    const fp = Math.min(1, cube.children[0].getBoundingClientRect().width / sizeMatch);
    const lp = Math.min(1, cube.children[3].getBoundingClientRect().width / sizeMatch);
    const tp = Math.min(1, cube.children[4].getBoundingClientRect().height / (sizeMatch * 0.5));
    const bp = Math.min(1, cube.children[5].getBoundingClientRect().height / (sizeMatch * 0.75));
    cube.style.transform = "rotateX(".concat(rx, "deg) rotateY(").concat(ry, "deg) rotateZ(").concat(rz, "deg)");
    cube.children[0].style.filter = "brightness(".concat(fp, ")");
    cube.children[3].style.filter = "brightness(".concat(lp, ")");
    cube.children[4].style.filter = "brightness(".concat(tp, ")");
    cube.children[5].style.filter = "brightness(".concat(bp + 0.75, ")");
    cube.dataset.delta = delta;
  };
  const update = () => {
    if (cubes.length) {
      cubes.forEach(cube => updateCube(cube));
      if (!shouldReduceMotion || !animationComplete) {
        requestAnimationFrameId = requestAnimationFrame(update);
      }
    }
  };
  const onAnimationComplete = e => {
    switch (e.animationName) {
      case 'cube-scale':
        animationComplete = true;
        break;
      default:
    }
  };
  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      entry.target.style = "\n        --height: ".concat(Math.round(entry.contentRect.height / 2), "px;\n        --width: ").concat(Math.round(entry.contentRect.width / 2), "px;\n      ");
    }
  });
  const reset = () => {
    elements.forEach(el => resizeObserver.unobserve(el));
    cancelAnimationFrame(requestAnimationFrameId);
  };
  logger.debug('Initializing section-text-image');
  reset();

  elements.forEach(el => {
    var _el$closest;
    if ((_el$closest = el.closest('.widget')) !== null && _el$closest !== void 0 && (_el$closest = _el$closest.nextSibling) !== null && _el$closest !== void 0 && _el$closest.querySelector('.category-menu-widget')) {
      el.classList.add('hero-sm-bottom-padding');
    }
    resizeObserver.observe(el);
    const brandCube = el.querySelector('.brand-cube');

    if (brandCube) {
      brandCube.removeEventListener('animationend', onAnimationComplete);
      brandCube.addEventListener('animationend', onAnimationComplete);
      const cube = brandCube.querySelector('.brand-cube-faces');
      if (cube) {
        cubes.push(cube);
      }
    }
    el.classList.remove('hero-loading');
  });
  requestAnimationFrameId = requestAnimationFrame(update);
  window.addEventListener('unload', reset);
};
const preview = document.getElementById('js-cms-preview-container');
if (preview) {
  const observer = new MutationObserver(() => initialize());
  observer.observe(preview, {
    childList: true
  });
}
svs.banner_layouts.section_text_image.init = initialize;

 })(window);